import request from "../utils/request"



export function getStatInfo(data) {
    return request({
        url: '/admin/stat/manufacturer/info',
        method: 'post',
        data
    })
}

export function updateManufacturerTag(data) {
    return request({
        url: '/admin/manufacturer/tag/update',
        method: 'post',
        data
    })
}